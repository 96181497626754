<template>
  <div class="table-container">
    <vca-popup
      v-if="current != null"
      :show="current != null"
      :title="card_title(current)"
      @close="current = null"
    >
      <DepositFinanceInfo v-model="current" />

      <div class="deposit-styles"><hr /></div>

      <div class="vca-center">
        <button
          :title="$t('button.edit')"
          class="vca-button-small"
          @click="edit(current.id)"
        >
          {{ $t("button.edit") }}
        </button>

        <button
          class="vca-button-small"
          v-if="current.status == 'wait' && hasSystemPermission()"
          type="confirm"
          @click="confirm(current)"
        >
          {{ $t("deposit.list.btn_deposit_wait") }}
        </button>
        <span v-else-if="current.status == 'wait' && !hasSystemPermission()">
          -
        </span>
        <button
          class="vca-button-small"
          v-else-if="current.status == 'open'"
          type="confirm"
          @click="submit(current)"
        >
          {{ $t("deposit.list.btn_deposit_open") }}
        </button>
      </div>
    </vca-popup>
    <VcaTable
      store="deposits"
      :list="pg_list"
      :colspan="9"
      :searchLabel="$t('deposit.list.search')"
    >
      <template v-slot:colspan>
        <col width="12%" />
        <col width="12%" v-if="hasSystemPermission('employee')" />
        <col width="22%" />
        <col width="10%" />
        <col width="10%" />
        <col width="8%" />
        <col width="8%" />
        <col width="12%" />
        <col width="1%" />
      </template>
      <template v-slot:desktop-filter>
        <vca-accordion :header="$t('deposit.list.filter')">
          <div slot="body">
            <DepositFilter v-model="filter" @reset="resetFilter()" />
          </div>
        </vca-accordion>
      </template>
      <template v-slot:desktop-header>
        <th class="vca-table-cell sortable" @click="sort('reason_for_payment')">
          <label>
            {{ $t("deposit.list.reason_for_payment") }}
            &varr;</label
          >
        </th>
        <th
          class="vca-table-cell sortable"
          @click="sort('crew.name')"
          v-if="hasSystemPermission('employee')"
        >
          <label> {{ $t("deposit.list.crew") }} &varr;</label>
        </th>
        <th class="vca-table-cell">
          <label> {{ $t("deposit.list.takings") }} </label>
        </th>
        <th class="vca-table-cell sortable" @click="sort('money.amount')">
          <label> {{ $t("deposit.list.total") }} &varr;</label>
        </th>
        <th class="vca-table-cell sortable" @click="sort('date_of_deposit')">
          <label> {{ $t("deposit.list.date_of_deposit") }} &varr;</label>
        </th>
        <th class="vca-table-cell sortable" @click="sort('status')">
          <label> {{ $t("deposit.list.status") }} &varr;</label>
        </th>
        <th class="vca-table-cell sortable" @click="sort('has_external')">
          <label> {{ $t("deposit.list.external") }} &varr;</label>
        </th>
        <th class="vca-table-cell">
          <label> {{ $t("table.options") }} </label>
        </th>
        <th class="vca-table-cell">
          <label>{{ $t("table.options") }}</label>
        </th>
      </template>
      <template v-slot:desktop-content>
        <tr
          class="vca-table-row"
          :class="{ last: index + 1 == pg.pageSize }"
          v-for="(res, index) in pg_list"
          :key="index"
        >
          <td class="vca-table-cell" @click="open(res)">
            <label class="link"> {{ res.reason_for_payment }} </label>
          </td>
          <td class="vca-table-cell" v-if="hasSystemPermission('employee')">
            <label> {{ res.crew.name }} </label>
          </td>
          <td class="vca-table-cell" id="depUnits">
            <vca-column :key="index" v-for="(unit, index) in res.deposit_units">
              <vca-row>
                <div first>
                  {{ unit.taking.name }}
                </div>
                <div last>{{ formatMoney(unit.money) }}</div>
              </vca-row>
            </vca-column>
          </td>
          <td class="vca-table-cell">
            <div></div>
            <span v-html="formatMoney(res.money)"></span>
          </td>
          <td class="vca-table-cell">
            <div></div>
            <span>{{ dateDeposit(res) }}</span>
          </td>
          <td class="vca-table-cell">
            <span> {{ $t("deposit.list." + res.status) }}</span>
          </td>
          <td class="vca-table-cell">
            <span v-if="res.has_external">&check;</span>
            <span v-else>✕</span>
          </td>
          <td class="vca-table-call">
            <label class="vca-right">
              <button
                class="vca-button-small"
                v-if="res.status == 'wait' && hasSystemPermission()"
                type="confirm"
                @click="confirm(res)"
              >
                {{ $t("deposit.list.btn_deposit_wait") }}
              </button>
              <span v-else-if="res.status == 'wait' && !hasSystemPermission()">
                -
              </span>
              <button
                class="vca-button-small"
                v-else-if="res.status == 'open'"
                type="confirm"
                @click="submit(res)"
              >
                {{ $t("deposit.list.btn_deposit_open_short") }}
              </button>
              <span v-else>
                {{ $t("deposit.list.confirmed") }}
              </span>
            </label>
          </td>
          <td class="vca-table-call">
            <label class="table-options">
              <img
                class="editable apply-filter"
                src="@/assets/icons/public.svg"
                @click="open(res)"
                :title="$t('button.preview')"
                :alt="$t('button.preview')"
              />
              <img
                v-if="showEditIcon(res)"
                class="editable"
                src="@/assets/icons/edit.png"
                @click="edit(res.id)"
                :title="$t('button.edit')"
                :alt="$t('button.edit')"
              />
              <img
                v-if="hasSystemPermission('admin') && res.status == 'confirmed'"
                @click="doSync(res)"
                class="editable"
                src="@/assets/icons/sync.png"
                :title="$t('button.sync')"
                :alt="$t('button.sync')"
              />
            </label>
          </td>
        </tr>
      </template>
      <template v-slot:mobile-filter>
        <button class="vca-button" @click="do_filter = true">
          {{ $t("deposit.list.filter") }}
        </button>
        <vca-popup
          v-if="do_filter"
          :show="do_filter"
          :title="$t('deposit.list.filter')"
          @close="do_filter = false"
        >
          <DepositFilter v-model="filter" />
        </vca-popup>
      </template>
      <template v-slot:mobile-header>
        <div class="sortable" @click="sort('reason_for_payment')">
          <label>{{ $t("deposit.list.reason_for_payment") }} &varr;</label>
        </div>
        <div class="sortable" @click="sort('crew.name')">
          <label>{{ $t("deposit.list.crew") }} &varr;</label>
        </div>
        <div class="sortable" @click="sort('status')">
          <label>{{ $t("deposit.list.status") }} &varr;</label>
        </div>
        <div class="sortable" @click="sort('email')">
          <label>{{ $t("deposit.list.date_of_deposit") }} &varr;</label>
        </div>
        <div class="vca-table-cell sortable" @click="sort('money.amount')">
          <label>{{ $t("deposit.list.total") }} &varr;</label>
        </div>
        <div class="vca-table-cell sortable" @click="sort('status')">
          <label>{{ $t("deposit.list.status") }} &varr;</label>
        </div>
      </template>
      <template v-slot:mobile-content>
        <tr
          class="vca-table-row"
          :class="{ last: index + 1 == pg.pageSize }"
          v-for="(res, index) in pg_list"
          :key="index"
        >
          <td class="vca-table-cell">
            <vca-row>
              <div>
                <vca-column>
                  <vca-row>
                    {{ res.reason_for_payment }} ({{ res.crew.name }})
                  </vca-row>
                </vca-column>
                <br />
                <vca-column
                  :key="index"
                  v-for="(unit, index) in res.deposit_units"
                >
                  <vca-row>
                    {{ unit.taking.name }} ({{ formatMoney(unit.money) }})
                  </vca-row>
                </vca-column>
              </div>
              <div>
                <label class="vca-right">
                  <button
                    class="vca-button-small"
                    v-if="res.status == 'wait' && hasSystemPermission()"
                    type="confirm"
                    @click="confirm(res)"
                  >
                    {{ $t("button.confirm") }}
                  </button>
                  <span
                    v-else-if="res.status == 'wait' && !hasSystemPermission()"
                  >
                    -
                  </span>
                  <button
                    class="vca-button-small"
                    v-else-if="res.status == 'open'"
                    type="confirm"
                    @click="submit(res)"
                  >
                    {{ $t("button.submit") }}
                  </button>
                  <div disabled v-else>
                    {{ $t("deposit.list.confirmed") }}
                  </div>
                </label>
              </div>
              <div style="justify-content: end; display: flex">
                <label class="table-options">
                  <img
                    class="editable apply-filter"
                    src="@/assets/icons/public.svg"
                    @click="open(res)"
                    :title="$t('button.preview')"
                    :alt="$t('button.preview')"
                  />
                  <img
                    class="editable"
                    src="@/assets/icons/edit.png"
                    @click="edit(res.id)"
                    :title="$t('button.edit')"
                    :alt="$t('button.edit')"
                  />
                  <img
                    v-if="
                      hasSystemPermission('admin') && res.status == 'confirmed'
                    "
                    @click="doSync(res)"
                    class="editable"
                    src="@/assets/icons/sync.png"
                    :title="$t('button.sync')"
                    :alt="$t('button.sync')"
                  />
                </label>
              </div>
            </vca-row>
          </td>
        </tr>
      </template>
    </VcaTable>
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import VcaTable from "@/components/utils/VcaTable";
import DepositFilter from "@/components/finance/deposits/list/DepositFilter";
import DepositFinanceInfo from "./DepositFinanceInfo.vue";
export default {
  name: "DepositTable",
  components: { VcaTable, DepositFilter, DepositFinanceInfo },
  data() {
    return {
      store: "deposits/pg",
      initial: true,
      do_filter: false,
      currentFilter: {},
      currentTextFilter: "",
      current: null,
    };
  },
  watch: {
    "pg.currentPage": {
      handler() {
        this.$store.dispatch({ type: `${this.store}/request` });
      },
      deep: true,
    },
    "pg.pageSize": {
      handler(value) {
        this.$store.commit(`${this.store}/pageSize`, value);
        if (this.pg.currentPageSize == 1) {
          this.$store.dispatch({ type: `${this.store}/request` });
        }
      },
      deep: true,
    },
    "pg.filterObject": {
      handler() {
        this.$store.dispatch({
          type: `${this.store}/request`,
          data: { inital: true, abort: true },
        });
      },
      deep: true,
    },
    "pg.filter": {
      handler() {
        this.$store.dispatch({
          type: `${this.store}/request`,
          data: { inital: true, abort: true },
        });
      },
      deep: true,
    },
  },
  created() {
    this.resetFilter();
    // sort descending
    this.sort("reason_for_payment");
    this.sort("reason_for_payment");
  },
  computed: {
    filter: {
      set(value) {
        this.pg.filterObject = value;
      },
      get() {
        return this.pg.filterObject;
      },
    },
    ...mapState({
      pg(state, getters) {
        if (this.store) {
          return getters[`${this.store}/pagination`];
        }
      },
    }),
    ...mapGetters({
      pg_list: "deposits/pg/list",
      hasSystemPermission: "user/roles/hasSystemPermission",
    }),
    emptyFilter() {
      return !this.pg.filter || this.pg.filter == "";
    },
    sameFilter() {
      return (
        Object.entries(this.currentFilter).toString() ===
          Object.entries(this.filter).toString() &&
        this.currentTextFilter == this.pg.filter
      );
    },
  },
  methods: {
    open(value) {
      this.current = value;
    },
    card_title(value) {
      return this.$t("deposit.info.header") + " " + value.reason_for_payment;
    },
    edit(value) {
      this.$router.push({ path: "/finances/deposit/edit/" + value });
    },
    doSync(res) {
      if (!confirm(this.$t("deposit.sync.confirm"))) {
        return false;
      }
      this.$store.dispatch({ type: "deposits/sync", data: res.id });
    },
    setCurrent(value) {
      if (this.current && this.current.id == value.id) {
        this.$store.commit("deposits/current", null);
      } else {
        this.$store.commit("deposits/current", value);
      }
    },
    formatList(value) {
      var takings = "";
      value.forEach((element) => {
        takings += element.taking.name + "<br>";
      });
      return takings;
    },
    submit(value) {
      value.status = "wait";
      value.money.currency = "EUR";
      this.$store.commit("deposits/edit", value);
      this.$store.dispatch("deposits/update");
    },
    confirm(value) {
      value.status = "confirmed";
      value.money.currency = "EUR";
      this.$store.commit("deposits/edit", value);
      this.$store.dispatch("deposits/update");
    },
    resetFilter() {
      this.filter = { ...this.currentFilter };
    },
    async sort(col) {
      await this.$store.dispatch({
        type: this.store + "/sort",
        col,
      });
      return;
    },
    showEditIcon(value) {
      if (!this.hasSystemPermission() && value.status == "confirmed") {
        return false;
      }
      return true;
    },
    dateDeposit(res) {
      if (res.date_of_deposit === 0) {
        return "-";
      }

      return this.date(res.date_of_deposit);
    },
  },
};
</script>
<style lang="scss" scoped>
.vca-button-small:hover {
  background-color: $blue;
  border-color: $blue;
}
#depUnits .vca-row div:first-child {
  flex-basis: 75%;
}
#depUnits .vca-row div:last-child {
  flex-basis: 25%;
  margin-left: 15px;
}
</style>
